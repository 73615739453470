import styled from 'styled-components';

import { DEVICE } from 'src/constants/device-sizes';
import { opaque } from 'src/utils/colors';

export const StyledRecaptchaCopyright = styled.span`
  display: block;
  position: fixed;
  bottom: 1.875rem;
  text-align: center;
  font-size: 0.75rem;
  left: 0;
  right: 0;

  @media ${DEVICE.laptop} and (orientation: landscape) {
    position: static;
    margin-top: 1.875rem;
  }

  a {
    color: ${props => props.theme.colors.blue};

    &:focus {
      box-shadow: 0 0 0 3px ${props => opaque(props.theme.colors.blue, 30)};
      border-radius: 3px;
    }
  }
`;
