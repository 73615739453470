import styled from 'styled-components';

import { DEVICE } from 'src/constants/device-sizes';

const OnfidoSpinnerWrap = styled.div`
  margin: 0 auto;
  height: 600px;
  width: 510px;
  background-color: ${props => props.theme.colors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${DEVICE.mobileL} {
    width: 100%;
    height: 930px;
  }
`;

export default OnfidoSpinnerWrap;
