import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Notification, ScrollToTop } from 'src/components';
import { REPRESENTATIVE_ROUTE } from 'src/constants';
import { apiInit } from 'src/libs/intergiro-client';
import { GlobalStyle, theme } from 'src/theme';

const AppRepresentative = React.lazy(() => import('src/introducers/representative'));
const AppIntroducers = React.lazy(() => import('src/introducers'));

apiInit({});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Notification />
      <BrowserRouter>
        <ScrollToTop />
        <React.Suspense fallback={null}>
          <Switch>
            <Route path={`/${REPRESENTATIVE_ROUTE}`} component={AppRepresentative} />
            <Route path={'/'} component={AppIntroducers} />
            <Redirect to={'/login'} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
