import { createTypography } from '@general/intergiro-ui-kit';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';

const black = '#111';
const red = '#EB5938';
const white = '#fff';
const grey = '#75757F';
const blue ='#455AA2';

export const theme = {
  colors: {
    background: white,
    black,
    blue,
    cream: '#FEE2BE',
    error: red,
    green: '#00A673',
    grey,
    greyDarkest: '#3E3C39',
    greyDark: '#403C39',
    greyLight: '#F3F4F5',
    greyLighter: '#F9F9F9',
    greyLightest: '#F6F7F8',
    greyMediumLight: '#E5E5E5',
    greyMediumDark: '#B2B2B9',
    greyMedium: grey,
    primary: black,
    red,
    text: black,
    white,
    yellow: '#EAC339',
    link: blue,
    orange: '#F79E1B',
  },
  fonts: createTypography('Gellix, Source Sans Pro, sans-serif'),
  button: {
    borderRadius: '1.875rem',
    hoverColor: grey,
    textColor: white,
  },
  boxShadow: '0px 0px 8px rgba(117, 117, 127, 0.25)',
  transition: 'all 200ms cubic-bezier(0.645, 0.045, 0.355, 1)',
};

export const BUTTON_BORDER_RADIUS = '1.875rem';
export const CONTROL_STYLES = { 'marginBottom': '1.25rem' };
export const LINK_BUTTON_HEIGHT = '1.3rem';

export const customizeTheme = customization => {
  const customTheme = { ...theme };

  if (customization) {
    customTheme.colors = { ...theme.colors, ...omitBy(customization.colors, v => v === '' || isNil(v)) };

    if (customization.fonts?.family) {
      customTheme.fonts = createTypography(customization.fonts.family);
    }

    if (customization.borderRadius) {
      customTheme.borderRadius = { ...omitBy(customization.borderRadius, isNil) };
    }
  }

  return customTheme;
};
