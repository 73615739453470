import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  align-items: center;
  background-color: ${props => props.theme.colors.greyLightest};
  border: 2px solid ${props => props.theme.colors.greyLightest};
  border-radius: .5rem;
  color: ${props => props.theme.colors.black};
  display: flex;
  font: ${props => props.theme.fonts.controlText};
  justify-content: space-between;
  margin-bottom: 1.25rem;
  padding: 1.5rem 1.375rem 1.5rem 1rem;
  transition: ${props => props.theme.transition};

  &:focus {
    border-color: ${props => props.theme.colors.blue};
  }

  svg {
    fill: ${props => props.theme.colors.grey};
    margin-left: 0.6875rem;
  }
`;

export const StyledName = styled.span`
  display: block;
  max-width: 90%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
`;
