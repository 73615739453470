import styled from 'styled-components';

import Hint from 'src/components/hint';

export const StyledDropZone = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-width: 2px;
  border-style: dashed;
  border-color: ${({ isActive, theme }) => isActive? theme.colors.blue : theme.colors.greyLightest};
  border-radius: .5rem;
  padding: 1rem 2rem 1rem 1rem;
  transition: ${({ theme }) => theme.transition};
  min-height: 4.5rem;
  text-align: left;
  position: relative;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};

  &:focus-within {
    border-color: ${props => props.theme.colors.blue};
  }
`;

export const StyledInner = styled.div`
  margin-left: 0.75rem;
`;

export const StyledNote = styled.span`
  display: block;
  font-size: 0.875rem;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: 0.25rem;
  white-space: pre-line;
`;

export const StyledLabel = styled.span`
  display: block;
  font: ${({ theme }) => theme.fonts.controlText};
  color: ${({ theme }) => theme.colors.greyDarkest};
  font-family: ${({ theme }) => theme.fonts.fontFamily};
`;

export const StyledIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  text-align: left;
  flex-shrink: 0;

  svg {
    fill: ${({ theme }) => theme.colors.greyDarkest};
    width: 100%;
    height: 100%;
  }
`;

export const StyledError = styled.span`
  font-size: 0.875rem;
  line-height: 1.0625;
  padding-left: 1.125rem;
  color: ${({ theme }) => theme.colors.red};
  font-weight: 400;
  margin-top: 0.375rem;
  text-align: left;
`;

export const StyledWrapper = styled.div`
  text-align: left;
  margin: 1.375rem 0 -0.5rem;
`;

export const StyledHint = styled(Hint)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;
