import styled from 'styled-components';

export const StyledCard = styled.li`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid  ${({ theme }) => theme.colors.greyLightest};
  border-radius: .5rem;
  padding: 0.875rem 1rem;
  min-height: 4.5rem;
  text-align: left;
  margin-bottom: 2rem;
  opacity: ${({ disabled }) => disabled ? 0.5 : 1};
`;

export const StyledButton = styled.button`
  padding: 0;
  font-size: 0;
  outline: none;
  cursor: pointer;
  border: 0;
  margin-left: .5rem;

  #removeActive {
    display: none;
  }

  &:hover, &:focus {
    #remove {
      display: none;
    }
    #removeActive {
      display: block;
    }
  }
`;

export const StyledPreview = styled.div`
  margin-right: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  flex-shrink: 0;

  svg {
    fill: ${({ theme }) => theme.colors.greyDarkest};
  }
`;

export const StyledImage = styled.img`
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.colors.greyLightest};
`;

export const StyledInner = styled.div`
  display: flex;
  align-items: center;
  font: ${({ theme }) => theme.fonts.text};
  color: ${({ theme }) => theme.colors.black};
  width: calc(100% - 3rem);
`;

export const StyledName = styled.span`
  max-width: calc(100% - 3rem);
  overflow: hidden;
  text-overflow: ellipsis;
`;
