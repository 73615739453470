import React from 'react';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line no-process-env
const App = require(`./App-${process.env.REACT_APP_BUILD_PROJECT}.js`).default;
import setupSentry from './sentry/sentry';

setupSentry();

const root = createRoot(document.getElementById('root'));
root.render(<App />);

