import { useEffect, useState } from 'react';

import { ERRORS } from 'src/constants/errors';
import { tostify } from 'src/utils/toast';

import { getDocumentsList } from './service';

export const useDocumentsList = query => {
  const [ isLoading, setLoading ] = useState(false);
  const [ documents, setDocuments ] = useState({});

  useEffect(() => {
    const fetchDocumentsList = async () => {
      setLoading(true);
      try {
        const data = await getDocumentsList(query);
        setDocuments(data);
      } catch (error) {
        tostify(ERRORS.generalError, 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentsList();
  }, [query]);

  return { isLoading, documents };
};
