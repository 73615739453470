export function groupBy(array, iteratee) {
  return array.reduce((result, value) => {
    const key = iteratee(value);
    if (result[key]) {
      result[key].push(value);
    } else {
      result[key] = [value];
    }

    return result;
  }, {});
}

