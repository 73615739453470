import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  border: 2px solid rgba(246, 247, 248, 0.9);
  padding: 1.5rem 1.1875rem;
  border-radius: .5rem;
  background-color: transparent;
  color: ${props => props.theme.colors.greyDarkest};
  font: ${props => props.theme.fonts.controlText};
  transition: ${props => props.theme.transition};

  &:focus {
    border-color: ${props => props.theme.colors.blue};
  }

  svg {
    fill: ${props => props.theme.colors.greyDarkest};
    width: 1.125rem;
    height: 1.125rem;
    margin-right: 0.6875rem;
  }
`;
