import styled from 'styled-components';

import { ReactComponent as ArrowRightIcon } from 'src/assets/arrow-right2.svg';
import { DEVICE } from 'src/constants/device-sizes';

export const StyledBusinessDescriptionHint = styled.div`
  border: 1px solid rgba(17, 17, 17, 0.1);
  border-radius: .5rem;
  margin-bottom: .5rem;
  padding: .75rem 1.25rem .5rem 1.25rem;
  text-align: left;
  font-weight: 500;
  line-height: 140%;
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
  svg{
    margin-bottom: .5rem;
  }
`;

export const StyledListTitle = styled.span`
  font-size: 0.875rem;
`;

export const StyledListItem = styled.li`
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
`;

export const StyledTransactionFlow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  
  @media ${DEVICE.mobileL} {
    flex-wrap:wrap;
    justify-content: space-around;
    flex-direction: column
  }
`;

export const StyledRightArrow = styled(ArrowRightIcon)`
  @media ${DEVICE.mobileL} {
    transform: rotate(90deg);
  }
`;
