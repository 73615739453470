export const ONFIDO_STEPS = [
  {
    type: 'document',
    options: {
      documentTypes: {
        driving_licence: false,
        passport: true,
        national_identity_card: true,
        residence_permit: false
      },
      forceCrossDevice: true,
    }
  },
  {
    type: 'face',
    options: {
      requestedVariant: 'video',
    }
  },
];
