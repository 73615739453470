import { Button } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

const ButtonWrapper = styled(Button)`
  font:  ${({ theme }) => theme.fonts.buttonText};
  min-width: 11rem;
  color: ${({ theme }) => theme.colors.white};
`;

export default ButtonWrapper;
