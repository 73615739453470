'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.getArray = exports.getBoolean = exports.getNumber = exports.getString = void 0;
function getString(varName, env = process.env) {
    const value = env[varName];
    return value === '' ? undefined : value;
}
exports.getString = getString;
function getNumber(varName, env = process.env) {
    const value = getString(varName, env);
    if (!value) {
        return undefined;
    }
    return Number(value);
}
exports.getNumber = getNumber;
function getBoolean(varName, env = process.env) {
    const value = getString(varName, env);
    if (!value) {
        return undefined;
    }
    if (value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }
    throw new Error(`Invalid boolean variable ${varName}: ${value}`);
}
exports.getBoolean = getBoolean;
function getArray(varName, separator = ',', env = process.env) {
    const value = getString(varName, env);
    if (!value) {
        return undefined;
    }
    return value.split(separator).map((v) => v.trim());
}
exports.getArray = getArray;
