import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as ArrowLeftIcon } from 'src/assets/arrow-left.svg';
import { LinkButtonWrapper, ProgressBar } from 'src/components';
import Hint from 'src/components/hint';
import { ROUTE_PREFIX } from 'src/constants';
import { signOut } from 'src/libs/intergiro-client';
import { isInvited, isSessionValid } from 'src/utils/storage';

import { INVITED_STAGES } from 'src/2d/constants/invited-stages';
import { STAGES } from 'src/2d/constants/stages';
import { INDIVIDUAL_STAGES } from 'src/3d/constants/individual-application';

import { StyledBackButton, StyledBackLink, StyledHeader, StyledInner, StyledLogout, StyledMain, StyledSubtitle, StyledTitle, StyledWrapper } from './styles';

const LOG_OUT_HINT = 'Your progress is saved up to this point. If you sign out you can resume your application at any time by logging back in.';

// TODO: split it on layout, 2d/layout and 3d/layout
function Layout({ title, subtitle, children, backLink, fullSize, stageId, isIndividual, backAction }) {
  let stages = STAGES;

  if (isIndividual) {
    stages = INDIVIDUAL_STAGES;
  }

  if (isInvited()) {
    stages = INVITED_STAGES;
  }

  const showProgressBar = () => {
    if (stages[stageId]) {
      const progress = stages[stageId].step * 100 / new Set(Object.values(stages).map(el => el.step)).size;

      return <ProgressBar progress={progress} />;
    }

    return null;
  };

  return (
    <StyledMain>
      <StyledWrapper>
        {showProgressBar()}
        {backLink && <StyledBackLink to={`${ROUTE_PREFIX}${backLink}`} id="back" ><ArrowLeftIcon /></StyledBackLink>}
        {backAction && <StyledBackButton onClick={backAction}><ArrowLeftIcon /></StyledBackButton>}
        {isSessionValid() && (<StyledLogout>
          <LinkButtonWrapper
            category="link"
            width="11rem"
            label="Log out"
            id="logOut"
            onClick={signOut}
            styles={{ marginRight: '0.3125rem', lineHeight: '1.525rem', height: '1.5rem' }}/>
          <Hint text={LOG_OUT_HINT} />
        </StyledLogout>
        )}
        <StyledHeader fullSize={fullSize}>
          {title && <StyledTitle>{title}</StyledTitle>}
          {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
        </StyledHeader>
        <StyledInner fullSize={fullSize}>
          {children}
        </StyledInner>
      </StyledWrapper>
    </StyledMain>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  backLink: PropTypes.string,
  backAction: PropTypes.func,
  fullSize: PropTypes.bool,
  stageId: PropTypes.string,
  isIndividual: PropTypes.bool,
};

Layout.defaultProps = {
  children: null
};

export default Layout;
