export const STAGES = {
  signup: {
    id: 'signup',
    step: 1,
    url: ''
  },
  confirmEmail: {
    id: 'confirmEmail',
    step: 2,
    url: 'confirm-email'
  },
  phoneNumber: {
    id: 'phoneNumber',
    step: 3,
    url: 'phone-number'
  },
  confirmPhoneNumber: {
    id: 'confirmPhoneNumber',
    step: 4,
    url: 'confirm-phone-number'
  },
  createPassword: {
    id: 'createPassword',
    step: 5,
    url: 'create-password'
  },
  enable2fa: {
    id: 'enable2fa',
    step: 6,
    url: 'personal-information'
  },
  personalInformation: {
    id: 'personalInformation',
    step: 7,
    url: 'personal-information'
  },
  personalAddress: {
    id: 'personalAddress',
    step: 8,
    url: 'personal-address'
  },
  authorisation: {
    id: 'authorisation',
    step: 9,
    url: 'authorisation'
  },
  aboutBusiness: {
    id: 'aboutBusiness',
    step: 10,
    url: 'about-business'
  },
  registeredCompanyAddress: {
    id: 'registeredCompanyAddress',
    step: 11,
    url: 'registered-company-address'
  },
  companyOperatingAddress: {
    id: 'companyOperatingAddress',
    step: 12,
    url: 'company-operating-address'
  },
  businessDetails: {
    id: 'businessDetails',
    step: 13,
    url: 'business-details'
  },
  invoicesDocuments: {
    id: 'invoicesDocuments',
    step: 14,
    url: 'invoices-documents'
  },
  customersDetails: {
    id: 'customersDetails',
    step: 15,
    url: 'customers-details'
  },
  paymentPreferences: {
    id: 'paymentPreferences',
    step: 16,
    url: 'payment-preferences'
  },
  transactionalInformation: {
    id: 'transactionalInformation',
    step: 17,
    url: 'transactional-information'
  },
  pricing: {
    id: 'pricing',
    step: 18,
    url: 'pricing'
  },
  companyDirectors: {
    id: 'companyDirectors',
    step: 19,
    url: 'company-directors'
  },
  companyOwners: {
    id: 'companyOwners',
    step: 20,
    url: 'company-owners'
  },
  supportingDocuments: {
    id: 'supportingDocuments',
    step: 21,
    url: 'supporting-documents'
  },
  personalVerification: {
    id: 'personalVerification',
    step: 22,
    url: 'personal-verification',
    subStages: {
      onfido: {
        url: 'onfido',
      }
    }
  },
  proofOfAddress: {
    id: 'proofOfAddress',
    url: 'proof-of-address',
    step: 23,
  },
  success: {
    id: 'success',
    step: 24,
    url: 'success'
  },
  reviewInProgress: {
    id: 'reviewInProgress',
    step: 24,
    url: 'review-in-progress'
  },
};
