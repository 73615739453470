import PropTypes from 'prop-types';
import React from 'react';

import { StyledProgressBar } from './styles';

function ProgressBar({ progress }) {
  return <StyledProgressBar progress={progress} />;
}

ProgressBar.propTypes = {
  progress: PropTypes.number
};
export default ProgressBar;
