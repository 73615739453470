import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import ErrorIcon from 'src/assets/error.svg';
import InfoIcon from 'src/assets/info.svg';
import SuccessIcon from 'src/assets/success.svg';

export const StyledContainer = styled(ToastContainer)`
  min-width: 22.5rem;

  @media only screen and (max-width: 480px) {
    min-width: auto;
  }


  .Toastify__toast {
    padding: 1.25rem 1.25rem 1.25rem 4.5rem;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.black};
    display: flex;
    align-items: center;
    margin-bottom: 0;
    font: ${props => props.theme.fonts.controlText};
    background-repeat: no-repeat;
    background-position: 1.25rem center;
    box-shadow: 0px 6px 16px rgba(64, 89, 167, 0.15);
  }

  .Toastify__toast-body {
    padding-right: 1.25rem;
    max-width: calc(100% - 2rem);
    white-space: pre-line;
  }

  .Toastify__toast--error {
    background-image: url(${ErrorIcon});
  }

  .Toastify__toast--info {
    background-image: url(${InfoIcon});
  }

  .Toastify__toast--success {
    background-image: url(${SuccessIcon});
  }
`;
