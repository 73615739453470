import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ReactComponent as DocIcon } from 'src/assets/doc.svg';
import { ReactComponent as DocumentIcon } from 'src/assets/document.svg';
import { ReactComponent as PdfIcon } from 'src/assets/pdf.svg';
import { ReactComponent as RemoveIcon } from 'src/assets/remove.svg';
import { ReactComponent as RemoveActiveIcon } from 'src/assets/remove-active.svg';
import { ReactComponent as XlsIcon } from 'src/assets/xls.svg';
import { getThumbnail } from 'src/libs/documents';

import { StyledButton, StyledCard, StyledImage, StyledInner, StyledName, StyledPreview } from './styles';

const useThumbnail = (id, fileName) => {
  const [ thumbnail, setThumbnail ] = useState(null);

  useEffect(() => {
    const fetchThumbnail = () => getThumbnail(id).then(response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });

      setThumbnail(blob);
    }).catch(() => setThumbnail(null));

    if (id && /(\.|\/)(jpeg|jpg|png)$/i.test(fileName)) {
      fetchThumbnail();
    }
  }, [ id, fileName ]);

  return { thumbnail };
};

function FileCard({ file, onRemove, disabled }) {
  const { name, id } = file;

  const { thumbnail } = useThumbnail(id, name);
  const [ source, setSource ] = useState(null);

  useEffect(() => {
    if (!id) {
      setSource(URL.createObjectURL(file));
    }

    if (thumbnail) {
      setSource(URL.createObjectURL(thumbnail));
    }
  }, [ id, file, thumbnail ]);

  const getPreview = () => {

    let preview = source  ? <StyledImage src={source} /> : <DocumentIcon/>;

    if (/(\.|\/)(docx|doc)$/i.test(name)) {
      preview = <DocIcon />;
    } else if (/(\.|\/)(pdf)$/i.test(name)) {
      preview = <PdfIcon />;
    } else if (/(\.|\/)(xlsx|xls)$/i.test(name)) {
      preview = <XlsIcon />;
    }

    return  <StyledPreview>{preview}</StyledPreview>;
  };

  return (
    <StyledCard disabled={disabled}>
      <StyledInner>
        {getPreview()}
        <StyledName>{name}</StyledName>
      </StyledInner>
      {onRemove ? (<StyledButton type="button" onClick={() => onRemove(file)} disabled={disabled}>
        <RemoveIcon id='remove' /><RemoveActiveIcon id='removeActive' />
          Remove document
      </StyledButton>) : null}
    </StyledCard>
  );
}

FileCard.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
  }).isRequired,
  onRemove: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
};

export default FileCard;
