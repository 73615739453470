export const STAGES = {
  personalVerification: 'personal_verification',
  address: 'address',
  uploadingPoaDocuments: 'uploading_poa_documents',
  aml: 'aml',
  passcode: 'passcode',
  biometry: 'biometry',
};

export const INDIVIDUAL_STAGES = {
  confirmPhoneNumber: {
    id: 'confirmPhoneNumber',
    url: '',
    step: 1
  },
  personalVerification: {
    id: 'personalVerification',
    url: 'personal-verification',
    step: 2,
    databaseName: 'personalVerification',
    subStages: {
      onfido: {
        url: 'onfido',
      }
    }
  },
  address: {
    id: 'address',
    url: 'address',
    step: 3,
    databaseName: 'address'
  },
  proofOfAddress: {
    id: 'proofOfAddress',
    url: 'proof-of-address',
    step: 4,
    databaseName: 'proofOfAddress'
  },
  aml: {
    id: 'aml',
    url: 'transactional-information',
    step: 5,
    databaseName: 'antiMoneyLaundering'
  },
  pepStatus: {
    id: 'pepStatus',
    url: 'pep-status',
    step: 6,
    databaseName: 'pepStatus'
  },
  createPasscode: {
    id: 'createPasscode',
    url: 'create-passcode',
    step: 7,
    databaseName: 'passcodeSetup'
  },
  setBiometry: {
    id: 'setBiometry',
    url: 'set-biometry',
    step: 8,
    databaseName: 'webAuthSetup'
  },
  success: {
    id: 'success',
    url: 'success',
    step: 9
  },
};

export const INDIVIDUAL_APPLICATION_STATUSES = {
  notStarted: 'not_started',
  started: 'started',
  dataSubmitted: 'data_submitted',
  completed: 'completed',
};
