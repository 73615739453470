import { Paragraph, Title } from '@general/intergiro-ui-kit';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { DEVICE } from 'src/constants/device-sizes';
import { opaque } from 'src/utils/colors';

export const StyledMain = styled.main`
  background-color: ${props => props.theme.colors.background};

  * {
    font-family: ${props => props.theme.fonts.fontFamily};
  }

  .select__control{
    background-color: ${props => props.theme.colors.greyLighter};
    border-color: ${props => props.theme.colors.greyLighter};
    &:hover {
      border-color: ${props => props.theme.colors.blue};
    }
    &:focus {
      border-color: ${props => props.theme.colors.blue};
    }
  }

  .select__control:enabled:hover {
    border-color:  ${props => props.theme.colors.blue};
  }

  .select__control--is-focused,
  .select__control--is-focused:hover {
    border-color:  ${props => props.theme.colors.blue};
  }

  .selected__input-has-value{
    font-size: 0.875rem!important;
  }
`;

export const StyledInner = styled.div`
  max-width: ${props => props.fullSize ? 'none' : '30rem'};
  margin: auto;
  text-align: center;
`;

export const StyledHeader = styled.div`
  min-height: ${props => props.fullSize ? 'auto' : '11.5rem'};
  padding-bottom: 2rem;

  @media (max-width: 550px), ${DEVICE.laptop} and (orientation: landscape) {
    min-height: ${props => props.fullSize ? 'auto' : '6.5rem'};
  }
`;

export const StyledTitle = styled(Title)`
  color: ${props => props.theme.colors.primaryText};
  font-size: 2.625rem;
  margin-bottom: 0.625rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  text-align: center;
  line-height: calc(2.8rem*1.2);
  
  @media ${DEVICE.laptop} {
    max-width: none;
  }

  @media (max-width: 550px) {
    font-size: 2rem;
    line-height: calc(2rem*1.3);
  }

  @media ${DEVICE.mobileL} {
    font-size: 1.625rem;
    text-align: left;
  }
`;

export const StyledSubtitle = styled(Paragraph)`
  color: ${props => props.theme.colors.secondaryText ?? props.theme.colors.grey};
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  white-space: pre-line;

  @media ${DEVICE.mobileL} {
    text-align: left;
  }

  @media (max-width: 550px) {
    line-height: calc(1.25rem*1.3);
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
  padding: 5rem 4rem;
  height: 100%;
  min-height: 100vh;

  @media ${DEVICE.mobileL} {
    padding: 3.75rem 1.25rem 1.875rem;
  }
`;

export const StyledLogout = styled.div`
  position: absolute;
  right: 4rem;
  top: 5.5rem;
  display: flex;
  align-items: flex-end;

  @media ${DEVICE.laptop} {
    top: 1.5625rem;
  }

  @media ${DEVICE.mobileL} {
    right: 1.25rem;
  }
`;

const getStyledButton = props => `
  position: absolute;
  top: 5.5rem;
  height: 3rem;
  width: 3rem;
  background-color: ${props.theme.colors.greyLight};
  border-radius: ${props.theme.borderRadius?.button || '50%'};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &:focus {
    box-shadow: 0 0 0 3px ${opaque(props.theme.colors.grey, 30)};
    border-radius: 50%;
  } 
  
  @media ${DEVICE.laptop} {
    top: 1.125rem;
    height: 2rem;
    width: 2rem;
    
    svg {
      width: 1.25rem;
      height: 0.75rem;
    }
  }
`;

export const StyledBackLink = styled(Link)`
  ${getStyledButton}
`;

export const StyledBackButton = styled.button`
  ${getStyledButton}
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
`;
