import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { DEVICE } from 'src/constants/device-sizes';
import gellixMediumWoff from 'src/fonts/Gellix-Medium.woff';
import gellixMediumWoff2 from 'src/fonts/Gellix-Medium.woff2';
import gellixRegularWoff from 'src/fonts/Gellix-Regular.woff';
import gellixRegularWoff2 from 'src/fonts/Gellix-Regular.woff2';
import gellixSemiBoldWoff from 'src/fonts/Gellix-SemiBold.woff';
import gellixSemiBoldWoff2 from 'src/fonts/Gellix-SemiBold.woff2';

import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
    ${normalize}

    @font-face {
      font-display: fallback;
      font-family: "Gellix";
      font-style: normal;
      font-weight: 400;
      src: local("Gellix"),
        url(${gellixRegularWoff2}) format("woff2"),
        url(${gellixRegularWoff}) format("woff");
    }

    @font-face {
      font-display: fallback;
      font-family: "Gellix";
      font-style: normal;
      font-weight: 500;
      src: local("Gellix-Medium"),
        url(${gellixMediumWoff2}) format("woff2"),
        url(${gellixMediumWoff}) format("woff");
    }

    @font-face {
      font-display: fallback;
      font-family: "Gellix";
      font-style: normal;
      font-weight: 600;
      src: local("Gellix-SemiBold"),
        url(${gellixSemiBoldWoff2}) format("woff2"),
        url(${gellixSemiBoldWoff}) format("woff");
    }

    html, body {
      height: 100%;
      font-size: 16px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: optimizeLegibility;
    }

    #root {
      height: 100%;
    }

    * {
      box-sizing: border-box;

      &:focus {
        outline: none;
      }
    }

    button, a {
      -webkit-tap-highlight-color: transparent;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    button[data-overlapping]:hover {
      @media (hover: none) {
        background-color:  ${theme.colors.buttonHover ?? theme.colors.black};
      }
    }

    .onfido-sdk-ui-Button-button-primary:not(:disabled):active,
    .onfido-sdk-ui-Button-button-primary:not(:disabled).onfido-sdk-ui-Button-hoverDesktop:active {
        background-color: #3B5098!important;
    }
    
    .onfido-sdk-ui-Button-button-secondary {
      color: ${theme.colors.blue}!important;
      border-color: ${theme.colors.blue}!important;
    }

    .onfido-sdk-ui-DocumentSelector-option.onfido-sdk-ui-DocumentSelector-optionHoverDesktop:hover {
      box-shadow: 0 0 0 2px ${theme.colors.blue}!important;
    }

    .grecaptcha-badge {
      visibility: hidden;
    }

    #onfido-sdk iframe{
      left: 0;
    }
    
    body div#hs-eu-cookie-confirmation {
      background-color: ${theme.colors.black} !important;
      box-shadow: none !important;
      font-size: 16px !important;
      font-family: "Gellix" ;

    div#hs-eu-cookie-confirmation-inner {
      background-color: ${theme.colors.black} !important;
      font-size: 1rem !important;
      margin: 0 auto !important;

      @media ${DEVICE.mobileL} {
        margin: 0 1.875rem !important;
      }

      #hs-eu-policy-wording,
      #hs-eu-cookie-disclaimer,
      p,
      p a {
        color: ${theme.colors.white} !important;
        font-size: 1rem !important;
      }

      div#hs-en-cookie-confirmation-buttons-area {
        text-align: center !important;

        a#hs-eu-confirmation-button,
        a#hs-eu-decline-button {
          background: ${theme.colors.white} !important;
          border: 1px solid ${theme.colors.white} !important;
          border-radius: 1.875rem !important;
          color: ${theme.colors.black} !important;
          display: inline-block;
          font-size: 1rem !important;
          letter-spacing: 0.05em;
          margin: 0.3125rem 0.9375rem !important;
          padding: 0.75rem 3.125rem !important;
          transition: ${theme.transition};
          font-family: "Gellix";

          &:hover {
            background: transparent !important;
            color: ${theme.colors.white} !important;
          }
        }

        a#hs-eu-decline-button {
          background: transparent !important;
          color: ${theme.colors.white} !important;

          &:hover {
            background: ${theme.colors.white} !important;
            color: ${theme.colors.black} !important;
          }
        }
      }
    }
  }
    
    .select__group {
        line-height: 1.4;
        font-weight: 400;
        .select__group-heading{
            font-size: 1rem;
            color: ${theme.colors.black};
            text-transform: capitalize;
            padding-left: 1rem;
        }
        
        .select__option{
            font-size: 0.875em;
            padding-left: 2rem;
        }
    }

    input {
        background-color: ${theme.colors.greyLighter};
    }

    .select__control, .select__option {
        text-align: left;
    }

    textarea {
        background-color: ${theme.colors.greyLightest};
    }
`;
