import { Accordion } from '@general/intergiro-ui-kit';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)`
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  border-style: solid;
  border-color: ${props => props.theme.colors.greyLightest};
  border-radius: 0.5rem;

  button:after,
  button:before {
    background: ${props => props.theme.colors.grey}
  }
`;

export const StyledAccordionContent = styled.span`
  display: inline-block;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  padding: 1rem 2rem 2rem 2rem;
`;
