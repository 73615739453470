import axios from 'axios';
import statusCodes from 'http-status';
import merge from 'lodash.merge';
import { snakeCase } from 'snake-case';

import config from 'src/config';
import { ERRORS } from 'src/constants/errors';
import { groupBy } from 'src/utils/group-by';
import { tostify } from 'src/utils/toast';

let token = null;
let getToken = null;
let onFail = null;
let refreshToken = null;

export const setToken = newToken => token = newToken;

export const init = ({ getToken: getTokenFn, onFail: onFailFn, refreshToken: refreshTokenFn }) => {
  getToken = getTokenFn;
  onFail = onFailFn;
  refreshToken = refreshTokenFn;
};

const httpRequest = async (method, url, data, additionalHeaders, responseType = 'json') => {
  const options = {
    method,
    url: `${config.apiUrl}/v1${url}`,
    data,
    headers: { 'x-access-token': getToken ? getToken() : token, ...additionalHeaders },
    responseType
  };

  try {
    const response = await axios(options);
    const { status } = response;

    if (status >= 200 && status < 300) {
      return response;
    }
  } catch (err) {
    const { status, data: errorData } = err.response;

    if (status === statusCodes.UNAUTHORIZED) {
      token = await refreshToken();

      if (token) {
        return httpRequest(method, url, data, additionalHeaders, responseType);
      }
    }

    return onFail(status, errorData);
  }
};

const mapToFormdata = data => {
  const formdata = new FormData();

  for (const key in data) {
    formdata.append(snakeCase(key), data[key]);
  }

  return formdata;
};

export const getThumbnail = async id => httpRequest('get', `/documents/${id}/thumbnail`, null, {}, 'blob');

export const deleteDocument = async id => httpRequest('delete', `/documents/${id}`);

export const uploadDocument = async data => httpRequest(
  'post',
  '/documents',
  mapToFormdata(data),
  { 'Content-Type': 'multipart/form-data' }
).then(({ data }) => data);

export const getDocumentsList = query => httpRequest('get', `/documents?${query}`).then(({ data }) => {
  const fullMap = {
    companyRegistryExtract: [],
    governmentId: [],
    memorandumOfAssociation: [],
    proofOfAddress: [],
    selfie: [],
    other: [],
  };

  const groupedDocuments = groupBy(data, ({ type }) => type);

  return merge(fullMap, groupedDocuments);
});

export const getDocuments = query => httpRequest('get', `/documents?${query}`).then(({ data }) => data);

export const getContent = id => fetch(`${config.apiUrl}/v1/documents/${id}/content`, { headers: { 'x-access-token': getToken ? getToken() : token } })
  .then(response => (response.status === 200 ? response.blob() : null));

export const downloadAndSaveFile = async id => {
  try {
    const content = await getContent(id);
    const url = URL.createObjectURL(content);
    const newWindow = window.open();
    newWindow.location.href = url;
  } catch (e) {
    tostify(ERRORS.filesError, 'error');
  }
};

export default {
  init, setToken, getDocumentsList, getDocuments, uploadDocument, deleteDocument, getContent, downloadAndSaveFile
};
