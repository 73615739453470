import PropTypes from 'prop-types';
import React from 'react';

import { ReactComponent as CrossIcon } from 'src/assets/cross.svg';

import { StyledCloseButton, StyledModal } from './styles';

function Modal({ children, ...props }) {
  return (
    <StyledModal
      {...props}
      ariaHideApp={false}
    >
      <StyledCloseButton onClick={props.closeModal} id="closeModal"><CrossIcon /></StyledCloseButton>
      {children}
    </StyledModal>
  );
}

Modal.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.node
};

export default Modal;
