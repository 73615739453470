import styled from 'styled-components';

import { DEVICE } from 'src/constants/device-sizes';

const ControlsWrapper = styled.div`
  margin-top: 1.875rem;
  margin-bottom: 5rem;

  @media ${DEVICE.tablet}, ${DEVICE.laptop} and (orientation: landscape) {
    margin-bottom: 1.875rem;
  }
`;

export default ControlsWrapper;
