import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DocumentIcon } from 'src/assets/document.svg';

import {
  StyledDropZone,
  StyledError,
  StyledHint,
  StyledIcon,
  StyledInner,
  StyledLabel,
  StyledNote,
  StyledWrapper,
} from './styles';

const FILE_MAX_SIZE = 20971520;

function UploadInput({ label, notes, onUpload, icon, hint, error, disabled, id }) {
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles, rejectedFiles) =>
    onUpload(acceptedFiles, rejectedFiles), [onUpload]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.jpeg, .jpg, .png, .docx, .doc, .pdf, .xlsx, .xls',
    maxSize: FILE_MAX_SIZE,
    noDrag: disabled
  });

  return (
    <StyledWrapper>
      <StyledDropZone {...getRootProps()} isActive={isDragActive} disabled={disabled} id={id}>
        <input {...getInputProps()} disabled={disabled} />
        <StyledIcon>{icon}</StyledIcon>
        <StyledInner>
          <StyledLabel>{t('Upload')} {label}</StyledLabel>
          <StyledNote>{notes || t('or drop your files here')}</StyledNote>
        </StyledInner>
        {hint ? <StyledHint text={hint} /> : null}
      </StyledDropZone>
      <StyledError>{error}</StyledError>
    </StyledWrapper>
  );
}

UploadInput.propTypes = {
  hint: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  error: PropTypes.string,
  icon: PropTypes.node,
  label: PropTypes.string,
  notes: PropTypes.string,
  id: PropTypes.string.isRequired,
  onUpload: PropTypes.func,
  disabled: PropTypes.bool.isRequired
};

UploadInput.defaultProps = {
  error: '',
  hint: '',
  icon: <DocumentIcon />,
  label: 'file',
  onUpload: () => {},
};

export default UploadInput;
