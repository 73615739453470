import styled from 'styled-components';

export const StyledTrigger = styled.i`
  svg {
    fill: ${props => props.theme.colors.grey};
  }
`;

export const StyledWrapper = styled.span`
  display: inline-flex;
`;

export const StyledTooltip = styled.span`
  display: block;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.5rem;
  box-shadow: 0px 6px 16px rgba(17, 17, 17, 0.1);
  box-sizing: border-box;
  color: ${props => props.theme.colors.black};
  font: ${props => props.theme.fonts.text};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.01em;
  max-width: 14.25rem;
  min-width: 12rem;
  padding: 1rem;
  text-align: left;
  z-index: 1;

  @media (max-width: 14.25rem) {
    max-width: 100%;
  }
`;
