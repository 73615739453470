export const SIZE = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '430px',
  tablet: '768px',
  laptop: '1023px',
  desktop: '1440px',
};

export const DEVICE = {
  mobileS: `(max-width: ${SIZE.mobileS})`,
  mobileM: `(max-width: ${SIZE.mobileM})`,
  mobileL: `(max-width: ${SIZE.mobileL})`,
  tablet: `(max-width: ${SIZE.tablet})`,
  laptop: `(max-width: ${SIZE.laptop})`,
  desktop: `(max-width: ${SIZE.desktop})`,
};
