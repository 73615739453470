import 'react-toastify/dist/ReactToastify.css';

import React from 'react';

import { ReactComponent as CrossIcon } from 'src/assets/cross.svg';

import { StyledContainer } from './styles';

function Notification() {
  return <StyledContainer
    autoClose={5000}
    position="bottom-left"
    hideProgressBar={true}
    draggable={false}
    closeButton={({ closeToast }) => <CrossIcon onClick={closeToast} />}
  />;
}

export default Notification;
