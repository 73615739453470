
import { Button, Spinner } from '@general/intergiro-ui-kit';
import { Onfido as OnfidoSDK } from 'onfido-sdk-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import OnfidoSpinnerWrap from 'src/components/onfido-spinner-wrapper';
import { BUTTON_BORDER_RADIUS } from 'src/theme';
import { mostReadableColorFromExists } from 'src/utils/colors';
import { getIndividualId } from 'src/utils/storage';

import { ONFIDO_STEPS } from './constants/default-configs';
import fallbackLanguage from './locales/en.json';
import { StyledWrapper } from './styles';

function initOnfidoSdk({ token, onComplete, theme, language, languagePhrases }) {
  const colorContentButtonSecondaryText = theme.colors.primaryText ?
    mostReadableColorFromExists(theme.colors.background, [
      theme.colors.secondaryText,
      theme.colors.primaryText,
      theme.colors.primary,
    ]) : theme.colors.grey;
  const inputBackground = theme.colors.primaryText || theme.colors.grey;

  return OnfidoSDK.init({
    token,
    onComplete,
    steps: ONFIDO_STEPS,
    language: {
      locale: language,
      phrases: languagePhrases || fallbackLanguage,
    },
    customUI: {
      googleFonts: [theme.fonts.fontFamily],
      fontSizeBody: '16px',
      borderRadiusButton: theme.borderRadius?.button || BUTTON_BORDER_RADIUS,
      fontSizeTitle: '2.3rem',
      fontWeightTitle: 600,
      fontWeightSubtitle: 500,
      fontWeightBody: 500,
      fontSizeSubtitle: '1.2rem',
      borderRadiusSurfaceModal: '8px',
      fontFamilyBody: theme.fonts.fontFamily,
      fontFamilyTitle: theme.fonts.fontFamily,
      fontFamilySubtitle: theme.fonts.fontFamily,
      colorContentTitle: theme.colors.primaryText || theme.colors.black,
      colorContentBody: theme.colors.primaryText || theme.colors.black,
      colorBackgroundInput: inputBackground,
      colorContentInput: mostReadableColorFromExists(inputBackground, [
        theme.colors.secondaryText,
        theme.colors.background,
        theme.colors.primary,
      ]),
      colorContentSubtitle: theme.colors.secondaryText || theme.colors.grey,
      colorBackgroundSurfaceModal: theme.colors.background,
      colorBackgroundSelector: theme.colors.background,
      colorBackgroundButtonPrimary: theme.colors.primary,
      colorBorderButtonPrimary: theme.colors.primary,
      colorContentButtonPrimaryText: theme.colors.greyLighter,
      colorBackgroundButtonPrimaryHover: theme.colors.buttonHover ||
        (getIndividualId() ? theme.colors.primary : theme.colors.grey),
      colorContentButtonSecondaryText: colorContentButtonSecondaryText,
      colorBackgroundButtonSecondaryHover: theme.colors.buttonHover ||
        (getIndividualId() ? theme.colors.background : theme.colors.grey),
      colorBackgroundButtonSecondary: theme.colors.background,
      colorBorderButtonSecondary: colorContentButtonSecondaryText,
      colorBackgroundInfoPill: theme.colors.secondaryText || theme.colors.greyMediumDark,
      colorContentInfoPill: theme.colors.background,
      colorContentLinkTextHover: theme.colors.buttonHover ||
        (getIndividualId() ? theme.colors.secondaryText : theme.colors.grey),
      colorBorderLinkUnderline: theme.colors.background,
      colorBackgroundLinkHover: theme.colors.background,
      colorBackgroundLinkActive: theme.colors.background,
      colorBackgroundDocTypeButton: theme.colors.background,
      colorBorderDocTypeButton: theme.colors.primaryText || theme.colors.black,
      colorContentDocTypeButton: theme.colors.primaryText || theme.colors.black,
    }
  });
}

function Onfido({
  language,
  languagePhrases,
  getSdkToken,
  onComplete
}) {
  const [ isLoading, setLoading ] = useState(true);
  const [ sdkToken, setSdkToken ] = useState(null);
  const isSimulator = sdkToken === null;
  const theme = useTheme();
  useEffect(() => {
    const getToken = async () => {
      setLoading(true);
      try {
        const token = await getSdkToken();
        setSdkToken(token);
      } finally {
        setLoading(false);
      }
    };

    getToken();
  }, [getSdkToken]);

  useEffect(() => {
    if (isLoading || isSimulator ) return;

    const onfidoOut = initOnfidoSdk({
      token: sdkToken,
      onComplete: data => onComplete({ faceSimilarityType: data?.face?.variant }),
      theme,
      language,
      languagePhrases,
    });

    return async () => await onfidoOut.tearDown();
  }, [ isLoading, isSimulator, onComplete ]);

  const onSkip = () => onComplete({ faceSimilarityType: 'standard' });

  return isLoading
    ? <OnfidoSpinnerWrap><Spinner/></OnfidoSpinnerWrap>
    : isSimulator
      ? (<>
        <p>Going through Onfido simulator</p>
        <Button label="Submit onfido" onClick={onSkip}/>
      </>)
      : <StyledWrapper id="onfido-mount"/>;

}

Onfido.propTypes = {
  language: PropTypes.string,
  languagePhrases: PropTypes.object,
  getSdkToken: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
};

Onfido.defaultProps = {
  language: 'en',
};

export default Onfido;
