export const INVITED_STAGES = {
  phoneNumber: {
    id: 'phoneNumber',
    step: 1,
    url: 'phone-number'
  },
  confirmPhoneNumber: {
    id: 'confirmPhoneNumber',
    step: 2,
    url: 'confirm-phone-number'
  },
  createPassword: {
    id: 'createPassword',
    step: 3,
    url: 'create-password'
  },
  enable2fa: {
    id: 'enable2fa',
    step: 4,
    url: 'personal-information'
  },
  personalInformation: {
    id: 'personalInformation',
    step: 5,
    url: 'personal-information'
  },
  personalAddress: {
    id: 'personalAddress',
    step: 6,
    url: 'personal-address'
  },
  personalVerification: {
    id: 'personalVerification',
    step: 7,
    url: 'personal-verification'
  },
  proofOfAddress: {
    id: 'proofOfAddress',
    url: 'proof-of-address',
    step: 8,
  },
  success: {
    id: 'success',
    step: 9,
    url: 'success'
  },
};

