import 'react';

import { StyledAccordion, StyledAccordionContent } from './styles';

export default function BusinessDescriptionExample() {
  const options = [
    {
      content: <StyledAccordionContent>
        The company provides marketing services to software developers with Sales outsourcing 50%,
        affiliate marketing 40%, business consulting 10%.
        Target businesses: software houses that provide video-on-demand services,
        e-learning courses and fitness applications.
        The biggest customer that the Company has is a software house from the UAE - Epic Consulting LLC
        in order to process their sales transactions and earn a commission from their account in the UK or UAE.
        Since the company is a marketing business, the main cost will be on employee
        and other software/media expenditure plus other corporate costs.
      </StyledAccordionContent>,
      title: 'Example of business description',
      isActive: false,
    }
  ];

  return (
    <div aria-hidden="true" onClick={e => e.preventDefault()}>
      <StyledAccordion options={options} />
    </div>
  );
}
