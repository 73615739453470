import Modal from 'react-modal';
import styled from 'styled-components';

import { opaque } from 'src/utils/colors';

export const StyledModal = styled(Modal).attrs({
  style: {
    overlay: {
      zIndex: 90,
      backgroundColor: 'rgba(117,117,127, .4)'
    }
  }
})`
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    background-color: ${props => props.theme.colors.white};
    transform: translate(-50%, -50%);
    margin-right: -50%;
    width: 36.25rem;
    box-shadow: 0px 6px 16px rgba(64, 89, 167, 0.15);
    border-radius: .5rem;
    padding: 3.75rem 3.125rem 3.125rem;
    text-align: center;
    overflow-y: auto;

    @media (max-width: 36.25rem), (max-width: 50.75rem) and (orientation: landscape) {
      width: 100%;
      height: 100%;
      padding: 3.75rem 1.25rem 1.875rem;
      text-align: left;
      background-color: rgba(255, 255, 255, 0.95);
    }
`;

export const StyledCloseButton = styled.button`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    outline: none;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0 3px ${props => opaque(props.theme.colors.grey, 30)};
      border-radius: 50%;
    }

    svg {
      width: 100%;
      height: 100%;
    }
`;
