export const ROUTE_PREFIX = '/onboarding';

export const INDIVIDUAL_ROUTE = 'individual-application';
export const REPRESENTATIVE_ROUTE = 'representative';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const REGEX_LATIN_SYMBOLS = {
  name: 'Latin symbols only',
  regex: /^[\p{Script_Extensions=Latin}\p{Punctuation}\p{Symbol}\p{Mark}\s]+$/u,
};

export const REGEX_LATIN_SYMBOLS_AND_NUMBERS = {
  name: 'Latin symbols and numbers only',
  regex: /^[\p{Script_Extensions=Latin}\p{Number}\p{Punctuation}\p{Symbol}\p{Mark}\s]+$/u,
};
