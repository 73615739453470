import PropTypes from 'prop-types';
import * as React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { ReactComponent as QuestionMarkIcon } from 'src/assets/question-mark.svg';

import { StyledTooltip, StyledTrigger, StyledWrapper } from './styles';

function Hint({ text, children, ...props }) {
  const isTablet = window.innerWidth <= 768;
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'top',
    trigger: isTablet ? 'click' : 'hover'
  });
  const QuestionMark =
    <StyledTrigger >
      <QuestionMarkIcon />
    </StyledTrigger>;

  return <>
    <StyledWrapper ref={setTriggerRef} {...props}>{children || QuestionMark}</ StyledWrapper>
    {visible && <StyledTooltip ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
      {text}
    </StyledTooltip>}
  </>;
}

Hint.propTypes = {
  text: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]).isRequired,
  children: PropTypes.node,
};

export default Hint;
