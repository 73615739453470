"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SchemaValidationError = void 0;
class SchemaValidationError extends Error {
    constructor(joiValidationError) {
        const message = `Config does not match provided schema: [${joiValidationError.details.map(d => d.message).join(', ')}]`;
        super(message);
        this.name = this.constructor.name;
        const currentStackTraceLimit = Error.stackTraceLimit;
        Error.stackTraceLimit = 2;
        Error.captureStackTrace(this, SchemaValidationError);
        Error.stackTraceLimit = currentStackTraceLimit;
    }
}
exports.SchemaValidationError = SchemaValidationError;
