import styled from 'styled-components';

export const StyledProgressBar = styled.div`
  position: absolute;
  left: 0.1875rem;
  right: 0.1875rem;
  top: 0.1875rem;
  border-radius: 0.1875rem;
  width: calc(${props => props.progress + 'vw'} - 0.375rem);
  height: 0.1875rem;
  background-color: ${props => props.theme.colors.blue};
  opacity: 0.4;
  z-index: 1;
`;
