import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import config from 'src/config';

export default function setupSentry() {
  if (!config.sentry.dsn) {
    return;
  }

  Sentry.init({
    dsn: config.sentry.dsn,
    environment: config.envName,
    enabled: config.sentry.enabled,
    integrations: [new Integrations.BrowserTracing()],
    dist: 'onboarding-ui',
    // eslint-disable-next-line no-process-env
    release: process.env.REACT_APP_VERSION,
    tracesSampleRate: 1.0,
  });
}
