import { ReactComponent as BankIcon } from 'src/assets/bank.svg';
import { ReactComponent as CustomersIcon } from 'src/assets/customers.svg';
import { ReactComponent as SuppliersIcon } from 'src/assets/suppliers.svg';

import {
  StyledBusinessDescriptionHint,
  StyledIconWrapper,
  StyledListItem,
  StyledListTitle,
  StyledRightArrow,
  StyledTransactionFlow } from './styles';

export default function BusinessDescriptionHint() {
  return (
    <StyledBusinessDescriptionHint>
      <StyledListTitle>
        Please describe your typical transaction flow, including the following details:
      </StyledListTitle>
      <ul>
        <StyledListItem>
          What goods/services you sell and who are your target customers?
        </StyledListItem>
        <StyledListItem>
          Which countries you sell the products/services into?
        </StyledListItem>
        <StyledListItem>
          Which channels do you market / sell your products/services?
        </StyledListItem>
        <StyledListItem>
          What goods/services do you purchase and where are your suppliers based?
        </StyledListItem>
        <StyledListItem>
          If you have not started trading yet,
          please tell us why and provide details about the planned launch.
        </StyledListItem>
      </ul>
      Transaction Flow
      <StyledTransactionFlow>
        <StyledIconWrapper>
          <CustomersIcon/>
          Customers
        </StyledIconWrapper>
        <StyledRightArrow/>
        <StyledIconWrapper>
          <BankIcon/>
          Your Account
        </StyledIconWrapper>
        <StyledRightArrow/>
        <StyledIconWrapper>
          <SuppliersIcon/>
          Suppliers
        </StyledIconWrapper>
      </StyledTransactionFlow>
    </StyledBusinessDescriptionHint>
  );
}
