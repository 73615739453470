import rgba from 'color-rgba';
import tinycolor from 'tinycolor2';

export const opaque = (color, opacity) => {
  const arr = rgba(color);
  const r = arr[0];
  const g = arr[1];
  const b = arr[2];
  const a = arr[3] * opacity / 100;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

function makeDark(color, step = 5) {
  while (!color.isDark()) {
    color.darken(step);
  }

  return color;
}

function makeLight(color, step = 5) {
  while (!color.isLight()) {
    color.lighten(step);
  }

  return color;
}

export const mostReadableColorFromExists = (bgColor, colors = []) => {
  const arrColors = colors.filter(Boolean);

  return tinycolor.mostReadable(bgColor, arrColors, { includeFallbackColors: false }).toHexString();
};

export const getMoreContrastTextColor = (bgColor, textColor) => {
  const bgTinyColor = tinycolor(bgColor);
  const textTinyColor = tinycolor(textColor);

  const bothAreLight = bgTinyColor.isLight() && textTinyColor.isLight();
  const bothAreDark = bgTinyColor.isDark() && textTinyColor.isDark();

  if (!bothAreLight && !bothAreDark) {
    return textColor;
  }

  const contrastColor = bothAreLight ? makeDark(textTinyColor) : makeLight(textTinyColor);

  return contrastColor.toString();
};
